// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-hoikupedia-js": () => import("./../../../src/pages/hoikupedia.js" /* webpackChunkName: "component---src-pages-hoikupedia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sozai-js": () => import("./../../../src/pages/sozai.js" /* webpackChunkName: "component---src-pages-sozai-js" */),
  "component---src-pages-weatherforecast-js": () => import("./../../../src/pages/weatherforecast.js" /* webpackChunkName: "component---src-pages-weatherforecast-js" */),
  "component---src-pages-wheel-js": () => import("./../../../src/pages/wheel.js" /* webpackChunkName: "component---src-pages-wheel-js" */)
}

